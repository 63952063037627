
import { defineComponent } from "vue";
export default defineComponent({
  name: "CardColorVertical",
  props: {
    title: String,
    desc: String,
    note: String,
    color: String,
    secondColor: String,
  },
  components: {},
});
