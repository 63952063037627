import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { DashboardWrapper } from "@/core/types/gws-agent/dashboard/DashboardWrapper";
import { ListItem } from "@/core/types/gws-agent/dashboard/ListItem";
import { FilterData } from "@/core/types/gws-agent/dashboard/FilterData";
import { DashboardAgentEnum } from "@/store/enums/DashboardAgentEnums";
import { formatInternationalDate } from "@/core/helpers/date-format";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";

const authStore = useAuthenticationStore();

export const useDashboardSyarikahStore = defineStore({
  id: "dashboardSyarikahStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateTotalQuota: {
        JOB_FAIR: {
          total_quota: 0,
          availbale_quota: 0,
        },
        P3MI: {
          total_quota: 0,
          availbale_quota: 0,
        },
        MEDICAL: {
          total_quota: 0,
          availbale_quota: 0,
        },
        BLK: {
          total_quota: 0,
          availbale_quota: 0,
        },
      },
      stateStatusMonitoring: [] as any[],
      stateFilterData: {
        date: [new Date(), new Date()],
      } as FilterData,
      stateDisplayData: [] as ListItem[],
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    displayTotalQuota(state) {
      return state.stateTotalQuota;
    },
    displayStatusMonitoring(state) {
      return state.stateStatusMonitoring;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      const [startDate, endDate] = this.stateFilterData.date;
      const params = {
        start_date: formatInternationalDate(startDate.toString()),
        end_date: formatInternationalDate(endDate.toString()),
        id: authStore?.user?.org_id || "",
        organization_type: authStore?.user?.org_type || "",
      };
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<
          ApiResponse<DashboardWrapper<ListItem[]>>
        > = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `dashboards/job_seeker?${new URLSearchParams(params).toString()}`
        );
        const { job_seekers } = response.data.data;

        const newArray = job_seekers.map((item) => {
          const key = Object.keys(item)[0];
          const subItem = item[key];
          return {
            name: DashboardAgentEnum[key],
            ...subItem,
          };
        });

        this.stateDisplayData = newArray;
      } catch (error) {
        console.log(error);
      }

      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `dashboards/monitoring_status?${new URLSearchParams(
            params
          ).toString()}`
        );
        const { monitoring_status } = response.data.data || {};
        const newArray = [
          {
            id: 1,
            name: String(DashboardAgentEnum["PRE_CLEARENCE_VFS"]),
            total: Number(
              monitoring_status?.PRE_CLEARENCE_VFS?.PASSED +
                monitoring_status?.PRE_CLEARENCE_VFS?.BLACKLIST
            ),
            children: [
              {
                id: 11,
                name: String(DashboardAgentEnum["PASSED"]),
                total: Number(monitoring_status?.PRE_CLEARENCE_VFS?.PASSED),
              },
              {
                id: 12,
                name: String(DashboardAgentEnum["BLACKLIST"]),
                total: Number(monitoring_status?.PRE_CLEARENCE_VFS?.BLACKLIST),
              },
            ],
          },
          {
            id: 2,
            name: String(DashboardAgentEnum["MEDICAL"]),
            total: Number(
              monitoring_status?.MEDICAL?.FIT +
                monitoring_status?.MEDICAL?.UNFIT
            ),
            children: [
              {
                id: 11,
                name: String(DashboardAgentEnum["FIT"]),
                total: Number(monitoring_status?.MEDICAL?.FIT),
              },
              {
                id: 12,
                name: String(DashboardAgentEnum["UNFIT"]),
                total: Number(monitoring_status?.MEDICAL?.UNFIT),
              },
            ],
          },
        ];

        this.stateStatusMonitoring = newArray;
      } catch (error) {
        console.log(error);
      }

      try {
        const responseJobSeekers: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/total_quota`
          );

        const { total_quota } = responseJobSeekers.data.data || {};

        this.stateTotalQuota = total_quota;
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
  },
});
